exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-index-js": () => import("./../../../src/pages/policies/index.js" /* webpackChunkName: "component---src-pages-policies-index-js" */),
  "component---src-templates-blog-post-js-content-file-path-blog-first-blog-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/github/workspace/blog/first-blog/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-blog-first-blog-index-mdx" */),
  "component---src-templates-policy-post-js-content-file-path-policies-cookie-policy-mdx": () => import("./../../../src/templates/policy-post.js?__contentFilePath=/github/workspace/policies/cookie-policy.mdx" /* webpackChunkName: "component---src-templates-policy-post-js-content-file-path-policies-cookie-policy-mdx" */),
  "component---src-templates-policy-post-js-content-file-path-policies-privacy-policy-mdx": () => import("./../../../src/templates/policy-post.js?__contentFilePath=/github/workspace/policies/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-policy-post-js-content-file-path-policies-privacy-policy-mdx" */),
  "component---src-templates-policy-post-js-content-file-path-policies-terms-and-conditions-mdx": () => import("./../../../src/templates/policy-post.js?__contentFilePath=/github/workspace/policies/terms-and-conditions.mdx" /* webpackChunkName: "component---src-templates-policy-post-js-content-file-path-policies-terms-and-conditions-mdx" */)
}

